//Some endpoints used for the nursing modules are on the student info Repository.

import { PedagogicalDiaryService } from "@/core/services/apis.service";
import ApiService from "@/core/services/api.service";

const RESOURCE_VITAL_SIGNS = "vital-signs";
const RESOURCE_CLINIC_VISIT = "clinic-visit";
const RESOURCE_EXTERNAL_PEOPLE = "external-people";
const RESOURCE_EXTERNAL_PEOPLE_CLASSIFICATION =
  "external-people-classification";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllVitalSigns = () => {
  return PedagogicalDiaryService.get(`${RESOURCE_VITAL_SIGNS}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getVitalSignByID = (uuid) => {
  return PedagogicalDiaryService.get(`${RESOURCE_VITAL_SIGNS}/${uuid}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const createVitalSign = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE_VITAL_SIGNS}`, payload);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const updateVitalSign = (uuid, payload) => {
  return PedagogicalDiaryService.put(`${RESOURCE_VITAL_SIGNS}`, uuid, payload);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const deleteVitalSign = (uuid) => {
  return PedagogicalDiaryService.delete(`${RESOURCE_VITAL_SIGNS}/${uuid}`);
};

//------------------------------------------------------------

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllClinicVisits = () => {
  return PedagogicalDiaryService.get(`${RESOURCE_CLINIC_VISIT}/all`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getStudentClinicVisits = () => {
  return PedagogicalDiaryService.get(`${RESOURCE_CLINIC_VISIT}/student`);
};

/**
 * GET request to fetch parameterized data
 * @returns {*}
 */
export const getStudentClinicVisitsPagination = (page, size) => {
  return PedagogicalDiaryService.getQuery(
    RESOURCE_CLINIC_VISIT,
    "student-pagination",
    {
      page,
      size,
    }
  );
};

export const getStudentClinicVisit = (studentCode) => {
  return PedagogicalDiaryService.getQuery(
    RESOURCE_CLINIC_VISIT,
    "student-clinic-visit",
    { studentCode }
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getStudentsClinicVisitsAmountByRange = (startDate, endDate) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_CLINIC_VISIT}/student/start-date/${startDate}/end-date/${endDate}`
  );
};
/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getStudentsClinicVisitsByRange = (studentUUID, startDate, endDate) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_CLINIC_VISIT}/student/${studentUUID}/start-date/${startDate}/end-date/${endDate}`
  );
};
/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getExternalPeopleClinicVisits = () => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_CLINIC_VISIT}/external-people`
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllClinicVisitsByDate = (date) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_CLINIC_VISIT}/visits/date/${date}`
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllClinicVisitsByDateRange = (
  studentUUID,
  startDate,
  endDate
) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_CLINIC_VISIT}/student/${studentUUID}/start-date/${startDate}/end-date/${endDate}`
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const createClinicVisit = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE_CLINIC_VISIT}`, payload);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const updateClinicVisit = (uuid, payload) => {
  return PedagogicalDiaryService.put(
    `${RESOURCE_CLINIC_VISIT}/${uuid}`,
    payload
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const deleteClinicVisit = (uuid) => {
  return PedagogicalDiaryService.delete(`${RESOURCE_CLINIC_VISIT}/delete-visit/${uuid}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getStudentGuideTeacherByGradeSectionOrSpecialityGroupID = (
  GradeSectionOrSpecialityGroupID,
  academicLevel
) => {
  return ApiService.get(
    `teacher-guide-groups/groupable/id/${GradeSectionOrSpecialityGroupID}/academic_level/${academicLevel}`
  );
};

//------------------------------------------------------------

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllExternalPeople = () => {
  return PedagogicalDiaryService.get(`${RESOURCE_EXTERNAL_PEOPLE}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getExternalPersonByID = (uuid) => {
  return PedagogicalDiaryService.get(`${RESOURCE_EXTERNAL_PEOPLE}/${uuid}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const createExternalPerson = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE_EXTERNAL_PEOPLE}`, payload);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const updateExternalPerson = (uuid, payload) => {
  return PedagogicalDiaryService.put(
    `${RESOURCE_EXTERNAL_PEOPLE}/${uuid}`,
    payload
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const deleteExternalPerson = (uuid) => {
  return PedagogicalDiaryService.delete(`${RESOURCE_EXTERNAL_PEOPLE}/${uuid}`);
};

//------------------------------------------------------------

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllExternalPeopleClassifications = () => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_EXTERNAL_PEOPLE_CLASSIFICATION}`
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getExternalPersonClassificationByID = (uuid) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE_EXTERNAL_PEOPLE_CLASSIFICATION}/${uuid}`
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const createExternalPersonClassification = (payload) => {
  return PedagogicalDiaryService.post(
    `${RESOURCE_EXTERNAL_PEOPLE_CLASSIFICATION}`,
    payload
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const updateExternalPersonClassification = (uuid, payload) => {
  return PedagogicalDiaryService.put(
    `${RESOURCE_EXTERNAL_PEOPLE_CLASSIFICATION}/${uuid}`,
    payload
  );
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const deleteExternalPersonClassification = (uuid) => {
  return PedagogicalDiaryService.delete(
    `${RESOURCE_EXTERNAL_PEOPLE_CLASSIFICATION}/${uuid}`
  );
};

//------------------------------------------------------------

export default {
  getAllVitalSigns,
  getVitalSignByID,
  createVitalSign,
  updateVitalSign,
  deleteVitalSign,
  getAllClinicVisits,
  getStudentClinicVisits,
  getExternalPeopleClinicVisits,
  getStudentsClinicVisitsByRange,
  getStudentsClinicVisitsAmountByRange,
  getAllClinicVisitsByDate,
  getAllClinicVisitsByDateRange,
  createClinicVisit,
  updateClinicVisit,
  getStudentGuideTeacherByGradeSectionOrSpecialityGroupID,
  deleteClinicVisit,
  getAllExternalPeople,
  getExternalPersonByID,
  createExternalPerson,
  updateExternalPerson,
  deleteExternalPerson,
  getAllExternalPeopleClassifications,
  getExternalPersonClassificationByID,
  createExternalPersonClassification,
  updateExternalPersonClassification,
  deleteExternalPersonClassification,
  getStudentClinicVisitsPagination,
  getStudentClinicVisit,
};
