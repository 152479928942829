<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="deep-purple lighten-2" class="elevation-0 px-3">
          <span class="headline">Gestionar enfermedades</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-container>
                <v-row class="mb-1">
                  <v-col cols="12" class="d-flex flex-row align-start">
                    <v-icon class="mr-2 mt-1"
                      >mdi-arrow-right-circle-outline</v-icon
                    >
                    <p class="text-body-1 mb-0">
                      Si desea agregar un registro nuevo, ingrese el nombre en
                      la barra de búsqueda y se habilitará el botón de
                      registrar.
                    </p>
                  </v-col>
                  <v-col cols="12" class="d-flex flex-row align-start">
                    <v-icon class="mr-2 mt-1"
                      >mdi-arrow-right-circle-outline</v-icon
                    >
                    <p class="text-body-1">
                      Para eliminar o actualizar un registro existente, puede
                      buscarlo por su nombre y seleccionarlo.
                    </p>
                  </v-col>
                </v-row>
                <v-text-field
                  v-model="search"
                  rounded
                  filled
                  label="Buscar padecimientos"
                  class="mb-1"
                  clearable
                ></v-text-field>

                <v-card v-if="filteredDiseases.length > 0" class="mx-auto">
                  <v-list class="scroll">
                    <v-list-item-group v-model="selectedDisease">
                      <template v-for="(item, i) in filteredDiseases">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          active-class="deep-purple--text text--accent-4"
                          @click="selectItemFromList(item)"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.name
                              }}</v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="deep-purple accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
                <v-card
                  v-else
                  class="d-flex justify-center align-center elevation-0 pa-3 flex-column"
                >
                  <p class="text-center mb-0">
                    No se encontró el padecimiento
                    <span class="font-weight-bold">"{{ this.search }}"</span>.
                    Si dese añadirlo a la lista, haga click en el botón
                    <span class="font-weight-bold"
                      >"Registrar padecimiento"</span
                    >.
                  </p>
                  <v-btn
                    color="primary lighten-1"
                    class="white--text mt-5"
                    :loading="createIsLoading"
                    elevation="0"
                    @click="create()"
                    >Registrar padecimiento</v-btn
                  >
                </v-card>
              </v-container>
            </v-col>
            <v-expand-transition>
              <v-col v-if="Object.keys(selectedItem).length > 0" cols="12">
                <v-container class="mt-n2">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <p class="mb-2 font-weight-bold text-body-1 text-center">
                        Editar medicamento seleccionado:
                      </p>
                      <form-group name="nombre" :validator="$v.diseases.name">
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Nombre de la enfermedad"
                          placeholder="Nombre del enfermedad. Ej.: Gripe"
                          required
                          filled
                          rounded
                          v-model="diseases.name"
                        />
                      </form-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-card-text>
        <!-- end form content -->
        <v-divider></v-divider>
        <v-card-actions class="pt-0">
          <v-btn
            @click="showMdlDelete"
            color="red lighten-1"
            class="white--text"
            elevation="0"
            :loading="deleteIsLoading"
            :disabled="!selectedDisease?.name"
          >
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="amber lighten-1"
            class="white--text"
            :loading="updateIsLoading"
            elevation="0"
            @click="update()"
            :disabled="!selectedDisease?.name"
          >
            Actualizar
          </v-btn>
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- begin::Modals -->
    <DeleteBaseModal ref="mdlDelete" @delete="deleteMedicine">
      <template v-slot:title>¿Desea eliminar esta enfermedad?</template>
      <template v-slot:subject>
        <span class="font-weight-bolder"> {{ `${diseases?.name}` }}</span>
      </template>
    </DeleteBaseModal>
    <!-- end::Modals -->
  </v-dialog>
</template>

<script>
import axios from "axios";
import { required, maxLength } from "vuelidate/lib/validators";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal";

export default {
  name: "ManageDiseasesModal",
  components: {
    DeleteBaseModal,
  },
  data() {
    return {
      selectedItem: {},
      search: "",
      updateIsLoading: false,
      createIsLoading: false,
      deleteIsLoading: false,
      dialog: false,
      selectedDisease: {
        name: null,
      },
      diseases: { name: null },
    };
  },
  props: {
    diseasesInfo: {
      type: Object,
    },
  },
  methods: {
    //Function that update the data
    async update() {
      this.$v.$touch();
      if (this.$v.diseases.name.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de ingresar un padecimiento válido",
        });
        return;
      }
      this.updateIsLoading = true;
      await axios
        .put(
          `${this.getFormApiURL}/diseases/${this.diseases.id}`,
          this.diseases
        )
        .then((response) => {
          this.updateIsLoading = false;
          this.sweetAlertResponse(response.data);
          this.$emit("reload");
          this.search = "";
          this.closeModal();
        })
        .catch((error) => {
          this.updateIsLoading = false;
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar la enfermedad",
          });
        });
    },
    selectItemFromList(item) {
      this.selectedItem = { ...item };
    },
    async create() {
      if (this.filteredDiseases > 0) {
        return;
      }

      this.$v.$touch();
      if (this.$v.search.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de ingresar un padecimiento válido",
        });
        return;
      }

      this.createIsLoading = true;
      await axios
        .post(`${this.getFormApiURL}/diseases`, { name: this.search, showInNursing: 1 })
        .then((response) => {
          this.createIsLoading = false;
          this.sweetAlertResponse(response.data);
          this.$emit("reload");
          this.search = "";
          this.closeModal();
        })
        .catch((error) => {
          this.createIsLoading = true;
          this.fireToast({
            icon: "error",
            title: "No ha sido posible crear la enfermedad",
          });
        });
    },

    //Function that deletes the selected diseases
    async deleteMedicine() {
      this.deleteIsLoading = true;
      await axios
        .put(`${this.getFormApiURL}/diseases/nursing/${this.diseases.id}`)
        .then((response) => {
          this.deleteIsLoading = false;
          this.sweetAlertResponse(response.data);
          this.$emit("reload");
          this.closeModal();
        })
        .catch((error) => {
          this.deleteIsLoading = false;
          this.fireToast({
            icon: "error",
            title: "No ha sido posible eliminar la enfermedad",
          });
        });
    },

    showMdlDelete() {
      if (!!this.diseases.id) {
        this.$refs.mdlDelete.toggleModal();
      } else {
        this.fireToast({
          icon: "warning",
          title: "No ha seleccionado ningún registro",
        });
      }
    },

    toggleModal() {
      this.dialog = !this.dialog;
      this.selectedItem = {};
      this.search = "";
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.clear();
    },
    clear() {
      this.selectedDisease = { name: null };
      this.diseases = { name: null };
    },
  },
  computed: {
    getFormApiURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
    filteredDiseases() {
      const searchQuery = this.search !== null ? this.search.toLowerCase() : "";
      return this.diseasesInfo.data.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery);
      });
    },
  },
  watch: {
    selectedDisease(newValue, oldValue) {
      if (newValue) {
        this.diseases.id = this.selectedDisease?.id;
        this.diseases.name = this.selectedDisease?.name;
      } else {
        this.clear();
      }
    },

    "diseases.name"(newValue) {
      if (newValue) {
        // Scroll to the target element when the condition becomes true
        this.$nextTick(() => {
          if (this.$refs.scrollTarget) {
            this.$refs.scrollTarget.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        });
      }
    },
  },
  validations: {
    search: {
      required,
    },
    diseases: {
      name: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.scroll::-webkit-scrollbar {
  width: 5px !important;
}
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
.scroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.scroll {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
  max-height: 250px;
  overflow-y: auto;
}
</style>
