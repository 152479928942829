<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="900"
  >
    <v-form>
      <v-card>
        <!-- form title -->
        <v-toolbar dark color="deep-purple lighten-2" class="elevation-0 px-3">
          <span class="headline">Gestionar signos vitales</span>
        </v-toolbar>
        <!-- form content -->
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-container>
                <span class="mb-5"
                  ><strong class="ml-1"
                    >Seleccione el registro a actualizar / eliminar:</strong
                  ></span
                >
                <v-card class="mx-auto">
                  <v-list shaped class="scroll">
                    <v-list-item-group v-model="selectedVitalSigns">
                      <template v-for="(item, i) in vitalSignsInfo.data">
                        <v-list-item
                          :key="`item-${i}`"
                          :value="item"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ item.name }} -
                                {{ item.measurement_unit }}</v-list-item-title
                              >
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                color="deep-purple accent-4"
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="6">
              <v-container class="mt-5">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <form-group name="nombre" :validator="$v.vitalSigns.name">
                      <v-text-field
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Nombre del signo vital"
                        placeholder="Nombre del signo vital. Ej.: Temperatura"
                        required
                        filled
                        rounded
                        v-model="vitalSigns.name"
                      />
                    </form-group>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <form-group
                      name="nombre"
                      :validator="$v.vitalSigns.measurement_unit"
                    >
                      <v-text-field
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Unidad de medida"
                        placeholder="Unidad de medida. Ej.: °C"
                        required
                        filled
                        rounded
                        v-model="vitalSigns.measurement_unit"
                      />
                    </form-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- end form content -->
        <v-divider></v-divider>
        <v-card-actions class="pt-0">
          <v-btn
            @click="showMdlDelete"
            color="red lighten-1"
            class="white--text"
            elevation="0"
            :loading="deleteIsLoading"
            :disabled="!selectedVitalSigns?.name"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary lighten-1"
            class="white--text"
            :loading="createIsLoading"
            elevation="0"
            @click="submit"
            :disabled="!!selectedVitalSigns?.name"
            >Crear</v-btn
          >
          <v-btn
            color="amber lighten-1"
            class="white--text"
            :loading="updateIsLoading"
            elevation="0"
            @click="submit"
            :disabled="!selectedVitalSigns?.name"
            >Actualizar</v-btn
          >
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- begin::Modals -->
    <DeleteBaseModal ref="mdlDelete" @delete="deleteMedicine">
      <template v-slot:title>¿Desea eliminar esta enfermedad?</template>
      <template v-slot:subject>
        <span class="font-weight-bolder"> {{ `${vitalSigns?.name}` }}</span>
      </template>
    </DeleteBaseModal>
    <!-- end::Modals -->
  </v-dialog>
</template>

<script>
import nursingRepository from "@/repositories/nursingRepository.js";
import { required, maxLength } from "vuelidate/lib/validators";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal";

export default {
  name: "ManageDiseasesModal",
  components: {
    DeleteBaseModal,
  },
  data() {
    return {
      updateIsLoading: false,
      createIsLoading: false,
      deleteIsLoading: false,
      dialog: false,
      selectedVitalSigns: {
        name: null,
        measurement_unit: null,
      },
      vitalSigns: { name: null, measurement_unit: null },
    };
  },
  props: {
    vitalSignsInfo: {
      type: Object,
    },
  },
  methods: {
    //Function that update the data
    update() {
      this.updateIsLoading = true;
      nursingRepository
        .updateVitalSign(this.vitalSigns.id, this.vitalSigns)
        .then(({ data }) => {
          this.sweetAlertResponse(data);
          this.$emit("reload");
          this.closeModal();
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el signo vital",
          });
        })
        .finally(() => {
          this.updateIsLoading = false;
        });
    },
    create() {
      this.createIsLoading = true;
      nursingRepository
        .createVitalSign(this.vitalSigns)
        .then(({ data }) => {
          this.sweetAlertResponse(data);
          this.$emit("reload");
          this.closeModal();
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el signo vital",
          });
        })
        .finally(() => {
          this.createIsLoading = false;
        });
    },
    //Function that deletes the selected vitalSigns
    deleteMedicine() {
      this.deleteIsLoading = true;
      nursingRepository
        .deleteVitalSign(this.vitalSigns.id)
        .then(({ data }) => {
          this.sweetAlertResponse(data);
          this.$emit("reload");
          this.closeModal();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible eliminar el signo vital",
          });
        })
        .finally(() => {
          this.deleteIsLoading = false;
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      if (!!this.vitalSigns.id) {
        this.update();
      } else {
        this.create();
      }
    },
    showMdlDelete() {
      if (!!this.vitalSigns.id) {
        this.$refs.mdlDelete.toggleModal();
      } else {
        this.fireToast({
          icon: "warning",
          title: "No ha seleccionado ningún registro",
        });
      }
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.clear();
    },
    clear() {
      this.selectedVitalSigns = { name: null, measurement_unit: null };
      this.vitalSigns = { name: null, measurement_unit: null };
    },
  },
  computed: {
    getFormApiURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
  },
  watch: {
    selectedVitalSigns(newValue, oldValue) {
      if (newValue) {
        this.vitalSigns.id = this.selectedVitalSigns?.id;
        this.vitalSigns.name = this.selectedVitalSigns?.name;
        this.vitalSigns.measurement_unit =
          this.selectedVitalSigns?.measurement_unit;
      } else {
        this.clear();
      }
    },
  },
  validations: {
    vitalSigns: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      measurement_unit: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.scroll::-webkit-scrollbar {
  width: 5px !important;
}
.scroll::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
.scroll::-webkit-scrollbar-thumb {
  background: #888 !important;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.scroll {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
  max-height: 250px;
  overflow-y: auto;
}
</style>
