<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Enfermería
            <span class="d-block text-muted pt-2 font-size-sm"
              >Puede registrar asistencia a enfermería , gestionar enfermedades,
              signos vitales, medicamentos y revisar el expediente de pacientes
              que han asistido a la enfermería.
            </span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <p class="text-h4 font-weight-medium text-center text-md-left">
          {{ currentDayFormat }}
        </p>
        <v-row class="d-flex flex-column-reverse flex-sm-row">
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-scroll-y-reverse-transition
              :hide-on-leave="true"
              :leave-absolute="true"
            >
              <v-card
                class="d-flex flex-column justify-center align-center pa-4 elevation-0 rounded-lg"
                outlined
                v-if="studentClinicVisitsToday.isLoading"
              >
                <v-progress-circular
                  :size="50"
                  :width="5"
                  color="cyan"
                  indeterminate
                ></v-progress-circular>
                <p class="text-center text-body-1 mt-3 font-weight-medium">
                  Cargando datos...
                </p>
              </v-card>
            </v-scroll-y-reverse-transition>

            <v-scroll-y-reverse-transition
              :hide-on-leave="true"
              :leave-absolute="true"
            >
              <SquaredIconCard
                v-if="!studentClinicVisitsToday.isLoading"
                colorTheme="cyan"
                icon="calendar"
                :title="`${countStudentVisistToday} estudiantes`"
                subTitle="atendidos hoy"
                iconCircleSize="80"
                iconSize="35"
              ></SquaredIconCard>
            </v-scroll-y-reverse-transition>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-scroll-y-reverse-transition
              :hide-on-leave="true"
              :leave-absolute="true"
            >
              <v-card
                class="d-flex flex-column justify-center align-center pa-4 elevation-0 rounded-lg"
                outlined
                v-if="clinicVisitsByMonth.isLoading"
              >
                <v-progress-circular
                  :size="50"
                  :width="5"
                  color="blue"
                  indeterminate
                ></v-progress-circular>
                <p class="text-center text-body-1 mt-3 font-weight-medium">
                  Cargando datos...
                </p>
              </v-card>
            </v-scroll-y-reverse-transition>

            <v-scroll-y-reverse-transition
              :hide-on-leave="true"
              :leave-absolute="true"
            >
              <SquaredIconCard
                v-if="!clinicVisitsByMonth.isLoading"
                colorTheme="blue"
                icon="calendar-month"
                :title="`${countClinicVisitByMonth} pacientes`"
                subTitle="atendidos en el mes"
                iconCircleSize="80"
                iconSize="35"
              ></SquaredIconCard>
            </v-scroll-y-reverse-transition>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="4"
            class="d-flex align-center justify-center flex-column"
          >
            <v-btn
              class="mb-2 d-flex justify-start elevation-0 red-border light-blue lighten-5 rounded-lg"
              block
              large
              @click="newClinicalVisit(true)"
            >
              <v-icon left dark size="28" class="mr-3" color="light-blue">
                mdi-plus-circle-outline
              </v-icon>
              <span class="font-weight-bold light-blue--text"
                >Visita estudiante</span
              >
            </v-btn>
            <v-btn
              class="mb-2 d-flex justify-start elevation-0 red-border blue lighten-5 rounded-lg"
              block
              large
              @click="newClinicalVisit(false)"
            >
              <v-icon left dark size="28" class="mr-3" color="blue">
                mdi-account-plus-outline
              </v-icon>
              <span class="font-weight-bold blue--text">Visita externa</span>
            </v-btn>
            <v-btn
              class="mb-2 d-flex justify-start elevation-0 red-border cyan lighten-5 rounded-lg"
              block
              large
              @click="goToStudentDisciplineDashboard"
            >
              <v-icon left dark size="28" class="mr-3" color="cyan">
                mdi-book-clock-outline
              </v-icon>
              <span class="font-weight-bold cyan--text"
                >Expediente de estudiante</span
              >
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-10 mb-5"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-card
              class="pr-md-10 mt-7 mt-md-0 pl-md-6 py-0 rounded-lg elevation-0"
            >
              <IconCardWithAction
                colorTheme="pink"
                icon="pill-multiple"
                :title="`${countMedicines} medicamentos`"
                subTitle="registrados"
                :loading="medicines.isLoading"
                buttonLegend="Gestionar"
                @action="showMdlMedicines"
              ></IconCardWithAction>
            </v-card>

            <v-card
              class="pr-md-10 mt-7 mt-md-0 pl-md-6 py-0 rounded-lg elevation-0"
            >
              <IconCardWithAction
                colorTheme="purple"
                icon="pulse"
                :title="`${countVitalSigns} signos vitales`"
                subTitle="registrados"
                :loading="vitalSigns.isLoading"
                buttonLegend="Gestionar"
                @action="showMdlVitalSigns"
              ></IconCardWithAction>
            </v-card>

            <v-card
              class="pr-md-10 mt-7 mt-md-0 pl-md-6 py-0 rounded-lg elevation-0"
            >
              <IconCardWithAction
                colorTheme="deep-purple"
                icon="hospital-box-outline"
                :title="`${countDiseases} enfermedades`"
                subTitle="registradas"
                :loading="diseases.isLoading"
                buttonLegend="Gestionar"
                @action="showMdlDiseases"
              ></IconCardWithAction>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-tabs v-model="tab" centered grow>
              <v-tab href="#tab-vs">Visitas estudiante</v-tab>
              <v-tab href="#tab-vex">Visitas persona externa</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-vs">
                <v-card class="elevation-0">
                  <v-card-title>
                    Visitas de estudiantes
                    <v-spacer></v-spacer>
                    <v-form
                      @submit.prevent="findStudentVisit"
                      class="d-flex justify-center align-center"
                    >
                      <v-text-field
                        v-model="studentsClinicVisits.search"
                        append-icon="mdi-magnify"
                        label="Buscar por código"
                        single-line
                        hide-details
                        rounded
                        filled
                        @keyup.enter="findStudentVisit"
                      ></v-text-field>
                      <v-btn
                        class="mx-2 light-blue lighten-4 elevation-0"
                        type="submit"
                        color="primary"
                        large
                      >
                        <span class="font-weight-bold light-blue--text"
                          >Buscar</span
                        ></v-btn
                      >
                      <v-btn
                        color="blue lighten-4 elevation-0"
                        large
                        @click="cleanSearchFilter"
                        ><span class="font-weight-bold blue--text"
                          >Limpiar filtro</span
                        ></v-btn
                      >
                    </v-form>
                  </v-card-title>
                  <v-data-table
                    :headers="studentVisitsHeaders"
                    :items="formattedStudentsClinicVisits"
                    :loading="studentsClinicVisits.isLoading"
                    :expanded.sync="expandedStudents"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    @update:options="getStudentsClinicVisits"
                    :footer-props="{
                      'items-per-page-options': [5, 10, 15, 20, 30],
                    }"
                    :items-per-page="20"
                    item-key="visit_id"
                    :single-expand="true"
                    show-expand
                  >
                    <!-- Student information -->
                    <template v-slot:[`item.full_name`]="{ item }">
                      <div class="py-2">
                        <div class="d-flex">
                          <!-- Student photo -->
                          <div>
                            <v-avatar size="70">
                              <img :src="item.photo" :alt="item.full_name" />
                              <!-- <v-icon color="red">mdi-account</v-icon> -->
                            </v-avatar>
                          </div>
                          <!-- Student Name and Code -->
                          <div class="ml-3 d-flex flex-column justify-center">
                            <p class="mb-0 text-h6 font-weight-bold">
                              {{ item.code }}
                            </p>
                            <p class="mb-0 text-body-1 font-weight-medium">
                              {{ item.full_name }}
                            </p>
                            <p
                              class="mb-0 text-body-1 font-weight-medium grey--text text--darken-1"
                            >
                              {{ item.grade }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- Visit date -->
                    <template v-slot:[`item.visit_date`]="{ item }">
                      <p class="text-body-1 font-weight-medium mb-0">
                        {{
                          fullFormattedDate(
                            item.visit_date,
                            "dddd D [de] MMMM [del] YYYY [a las] h:mm A"
                          )
                        }}
                      </p>
                    </template>

                    <!-- Collapsable content -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row class="my-5 mx-2">
                          <v-col cols="12" md="4">
                            <v-card
                              outlined
                              class="rounded-lg pa-5 d-flex flex-column align-center"
                            >
                              <v-avatar
                                size="80"
                                color="pink lighten-5"
                                class="mb-3"
                              >
                                <v-icon large color="pink"
                                  >mdi-pill-multiple</v-icon
                                >
                              </v-avatar>
                              <div
                                class="d-flex flex-column align-start"
                                v-if="item.medication.length > 0"
                              >
                                <p class="text-h6 font-weight-bold">
                                  Medicamentos suministrados
                                </p>
                                <ul class="my-2">
                                  <li
                                    v-for="(vital, index) in item.medication"
                                    :key="index"
                                  >
                                    <span class="font-weight-bold"
                                      >{{ vital.medicine }}:</span
                                    >
                                    <span class="font-weight-medium">{{
                                      vital.dosis_supplied
                                    }}</span>
                                  </li>
                                </ul>
                              </div>

                              <div v-else>
                                <p
                                  class="text-h6 font-weight-medium text-center"
                                >
                                  No se suministraron medicamentos.
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-card
                              outlined
                              class="rounded-lg pa-5 d-flex flex-column align-center"
                            >
                              <v-avatar
                                size="80"
                                color="purple lighten-5"
                                class="mb-3"
                              >
                                <v-icon large color="purple">mdi-pulse</v-icon>
                              </v-avatar>
                              <div
                                class="d-flex flex-column align-start"
                                v-if="item.vital_signs.length > 0"
                              >
                                <p class="text-h6 font-weight-bold">
                                  Signos vitales registrados
                                </p>
                                <ul class="my-2">
                                  <li
                                    v-for="(vital, index) in item.vital_signs"
                                    :key="index"
                                  >
                                    <span class="font-weight-bold"
                                      >{{ vital.VitalSign.name }}:</span
                                    >
                                    <span class="font-weight-medium">
                                      {{ vital.measurement
                                      }}{{ vital.VitalSign.measurement_unit }}
                                    </span>
                                  </li>
                                </ul>
                              </div>

                              <div v-else>
                                <p
                                  class="text-h6 font-weight-medium text-center"
                                >
                                  No se registraron signos vitales.
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-card
                              outlined
                              class="rounded-lg pa-5 d-flex flex-column align-center"
                            >
                              <v-avatar
                                size="80"
                                color="deep-purple lighten-5"
                                class="mb-3"
                              >
                                <v-icon large color="deep-purple"
                                  >mdi-hospital-box-outline</v-icon
                                >
                              </v-avatar>
                              <div
                                class="d-flex flex-column align-start"
                                v-if="item.vital_signs.length > 0"
                              >
                                <p class="text-h6 font-weight-bold">
                                  Padecimiento registrado
                                </p>
                                <ul class="my-2">
                                  <li
                                    v-for="(vital, index) in item.illness"
                                    :key="index"
                                  >
                                    <span class="font-weight-bold">{{
                                      vital.disease
                                    }}</span>
                                  </li>
                                </ul>
                              </div>

                              <div v-else>
                                <p
                                  class="text-h6 font-weight-medium text-center"
                                >
                                  No se registró padecimiento.
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12">
                            <p class="text-body-1 font-weight-bold">
                              Diagnóstico:
                            </p>
                            <p class="text-body-1 font-weight-medium">
                              "{{ item.diagnostic }}"
                            </p>
                          </v-col>
                        </v-row>
                      </td>
                    </template>

                    <!-- Download report button -->
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :loading="item.loading"
                            :disabled="item.loading"
                            icon
                            @click="
                              getStudentVisitDetailedReport(
                                item.visit_id,
                                item.visit_date,
                                item.code
                              )
                            "
                            v-bind="attrs"
                            v-on="on"
                            color="primary lighten-5"
                          >
                            <v-icon
                              color="primary
"
                              >mdi-file</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Generar reporte de visíta</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :loading="item.loading"
                            :disabled="item.loading"
                            icon
                            @click="selectItem(item), showMdlDelete()"
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                          >
                            <v-icon
                              color="red
"
                            >
                              mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Eliminar visita</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
              <v-tab-item value="tab-vex">
                <v-card class="elevation-0">
                  <v-card-title>
                    Visitas personas externas
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="externalClinicVisits.search"
                      append-icon="mdi-magnify"
                      label="Buscar por nombre o área"
                      single-line
                      hide-details
                      rounded
                      filled
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="externalVisitsHeaders"
                    :items="formattedExternalPeopleClinicVisits"
                    :loading="externalClinicVisits.isLoading"
                    :search="externalClinicVisits.search"
                    :expanded.sync="expandedPeople"
                    :items-per-page="10"
                    item-key="visit_id"
                    :single-expand="true"
                    show-expand
                  >
                    <!-- Person information -->
                    <template v-slot:[`item.full_name`]="{ item }">
                      <div class="py-2">
                        <div class="d-flex">
                          <!-- photo -->
                          <div>
                            <v-avatar size="70" color="primary lighten-5">
                              <!-- <img :src="item.photo" :alt="item.full_name" /> -->
                              <v-icon color="primary" large
                                >mdi-account-outline</v-icon
                              >
                            </v-avatar>
                          </div>
                          <!-- Person Name -->
                          <div class="ml-3 d-flex flex-column justify-center">
                            <p class="mb-0 text-body-1 font-weight-medium">
                              {{ item.full_name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </template>

                    <!-- Visit date -->
                    <template v-slot:[`item.visit_date`]="{ item }">
                      <p class="text-body-1 font-weight-medium mb-0">
                        {{
                          fullFormattedDate(
                            item.visit_date,
                            "dddd D [de] MMMM [del] YYYY [a las] h:mm A"
                          )
                        }}
                      </p>
                    </template>

                    <!-- Patient clasification -->
                    <template v-slot:[`item.area`]="{ item }">
                      <p class="text-body-1 font-weight-medium mb-0">
                        <v-chip
                          color="primary lighten-5 primary--text font-weight-medium"
                        >
                          {{ item.patient_clasification }}
                        </v-chip>
                      </p>
                    </template>

                    <!-- Collapsable content -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row class="my-5 mx-2">
                          <v-col cols="12" md="4">
                            <v-card
                              outlined
                              class="rounded-lg pa-5 d-flex flex-column align-center"
                            >
                              <v-avatar
                                size="80"
                                color="pink lighten-5"
                                class="mb-3"
                              >
                                <v-icon large color="pink"
                                  >mdi-pill-multiple</v-icon
                                >
                              </v-avatar>
                              <div
                                class="d-flex flex-column align-start"
                                v-if="item.medication.length > 0"
                              >
                                <p class="text-h6 font-weight-bold">
                                  Medicamentos suministrados
                                </p>
                                <ul class="my-2">
                                  <li
                                    v-for="(vital, index) in item.medication"
                                    :key="index"
                                  >
                                    <span class="font-weight-bold"
                                      >{{ vital.medicine }}:</span
                                    >
                                    <span class="font-weight-medium">
                                      {{ vital.dosis_supplied }}
                                    </span>
                                  </li>
                                </ul>
                              </div>

                              <div v-else>
                                <p
                                  class="text-h6 font-weight-medium text-center"
                                >
                                  No se suministraron medicamentos.
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <!-- ++++++++++++++++ -->
                          <v-col cols="12" md="4">
                            <v-card
                              outlined
                              class="rounded-lg pa-5 d-flex flex-column align-center"
                            >
                              <v-avatar
                                size="80"
                                color="purple lighten-5"
                                class="mb-3"
                              >
                                <v-icon large color="purple">mdi-pulse</v-icon>
                              </v-avatar>
                              <div
                                class="d-flex flex-column align-start"
                                v-if="item.vital_signs.length > 0"
                              >
                                <p class="text-h6 font-weight-bold">
                                  Signos vitales registrados
                                </p>
                                <ul class="my-2">
                                  <li
                                    v-for="(vital, index) in item.vital_signs"
                                    :key="index"
                                  >
                                    <span class="font-weight-bold"
                                      >{{ vital.VitalSign.name }}:</span
                                    >
                                    <span class="font-weight-medium">
                                      {{ vital.measurement
                                      }}{{ vital.VitalSign.measurement_unit }}
                                    </span>
                                  </li>
                                </ul>
                              </div>

                              <div v-else>
                                <p
                                  class="text-h6 font-weight-medium text-center"
                                >
                                  No se registraron signos vitales.
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <!-- ++++++++++++++++ -->
                          <v-col cols="12" md="4">
                            <v-card
                              outlined
                              class="rounded-lg pa-5 d-flex flex-column align-center"
                            >
                              <v-avatar
                                size="80"
                                color="deep-purple lighten-5"
                                class="mb-3"
                              >
                                <v-icon large color="deep-purple"
                                  >mdi-hospital-box-outline</v-icon
                                >
                              </v-avatar>
                              <div
                                class="d-flex flex-column align-start"
                                v-if="item.illness.length > 0"
                              >
                                <p class="text-h6 font-weight-bold">
                                  Padecimiento registrado
                                </p>
                                <ul class="my-2">
                                  <li
                                    v-for="(vital, index) in item.illness"
                                    :key="index"
                                  >
                                    <span class="font-weight-bold">{{
                                      vital.disease
                                    }}</span>
                                  </li>
                                </ul>
                              </div>

                              <div v-else>
                                <p
                                  class="text-h6 font-weight-medium text-center"
                                >
                                  No se registró padecimiento.
                                </p>
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12">
                            <p class="text-body-1 font-weight-bold">
                              Diagnóstico:
                            </p>
                            <p class="text-body-1 font-weight-medium">
                              "{{ item.diagnostic }}"
                            </p>
                          </v-col>
                        </v-row>
                      </td>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
      <ManageMedicineModal
        ref="mdlMedicine"
        :medicinesInfo="medicines"
        @reload="getMedicines"
      ></ManageMedicineModal>
      <ManageDiseaseModal
        ref="mdlDisease"
        :diseasesInfo="diseases"
        @reload="getDiseases"
      ></ManageDiseaseModal>
      <ManageVitalSignsModal
        ref="mdlVistalSigns"
        :vitalSignsInfo="vitalSigns"
        @reload="getVitalSigns"
      >
      </ManageVitalSignsModal>
      <DeleteBaseModal ref="mdlDelete" @delete="deleteVisit">
        <template v-slot:title>¿Desea eliminar esta visita?</template>
        <template v-slot:subject>
          <span class="font-weight-bolder">la visita</span>
        </template>
      </DeleteBaseModal>
    </div>
    <!-- end::Card -->
  </div>
</template>

<script>
import axios from "axios";
import nursingRepository from "@/repositories/nursingRepository.js";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import dayjs from "dayjs";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ManageMedicineModal from "@/components/modals/clinical_nursing/ManageMedicineModal.vue";
import ManageDiseaseModal from "@/components/modals/clinical_nursing/ManageDiseaseModal.vue";
import ManageVitalSignsModal from "@/components/modals/clinical_nursing/ManageVitalSignsModal.vue";
import SquaredIconCard from "@/components/elements/dashboard/SquaredIconCard.vue";
import IconCardWithAction from "@/components/elements/dashboard/IconCardWithAction.vue";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";

export default {
  name: "ClinicalNursingDashboard",
  title: "Enfermería | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Enfermería", route: "clinical_nursing" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Dashboard enfermeria");
    this.getCurrentDay();
    this.getMedicines();
    this.getDiseases();
    this.getVitalSigns();
    this.getStudentsClinicVisits();
    this.getExternalClinicVisits();
    this.getStudentClinicVisitsToday();
    this.getClinicVisitsByMonth();
  },

  components: {
    SquaredIconCard,
    IconCardWithAction,
    ManageMedicineModal,
    ManageDiseaseModal,
    ManageVitalSignsModal,
    DeleteBaseModal,
  },

  data() {
    return {
      downloadingButton: false,
      expandedStudents: [],
      expandedPeople: [],
      tab: null,
      currentDayFormat: "",
      currentDay: "",
      studentVisitsHeaders: [
        {
          text: "Estudiante",
          align: "start",
          value: "full_name",
        },

        { text: "Fecha de visita", value: "visit_date" },
        { text: "Más detalles", value: "data-table-expand", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      externalVisitsHeaders: [
        {
          text: "Nombre",
          align: "start",
          value: "full_name",
        },
        { text: "Fecha de visita", value: "visit_date" },
        {
          text: "Clasificación paciente",
          value: "area",
        },
        { text: "Más detalles", value: "data-table-expand", sortable: false },
      ],
      medicines: {
        data: [],
        isLoading: false,
      },
      diseases: {
        data: [],
        isLoading: false,
      },
      vitalSigns: {
        data: [],
        isLoading: false,
      },
      clinicVisits: {
        data: [],
        isLoading: false,
      },
      studentsClinicVisits: {
        data: [],
        isLoading: false,
        search: "",
      },
      externalClinicVisits: {
        data: [],
        isLoading: false,
        search: "",
      },
      studentClinicVisitsToday: {
        data: [],
        isLoading: false,
      },
      clinicVisitsByMonth: {
        data: [],
        isLoading: false,
      },
      options: {},
      totalItems: 0,
      itemsPerPageTable: [5, 10, 15, 20],
      selectedVisit: {},
    };
  },

  methods: {
    getCurrentDay() {
      // Set locale to Spanish
      dayjs.locale("es");
      const today = dayjs();
      this.currentDay = today.format("YYYY-MM-DD");
      this.currentDayFormat = today.format("dddd, DD [de] MMMM [de] YYYY");
    },

    newClinicalVisit(isStudent) {
      const nursingCatalogs = {
        medicines: this.medicines.data,
        diseases: this.diseases.data,
        vitalSigns: this.vitalSigns.data,
      };

      this.$store.dispatch("getCatalogsInfo", nursingCatalogs);

      if (isStudent) {
        this.$router.push({
          path: "/clinical_nursing/new_student_clinical_visit",
          query: {
            student: isStudent,
          },
        });
      } else {
        this.$router.push({
          path: "/clinical_nursing/new_external_clinical_visit",
        });
      }
    },

    getExternalClinicVisits() {
      this.externalClinicVisits.isLoading = true;
      nursingRepository
        .getExternalPeopleClinicVisits()
        .then(({ data }) => {
          this.externalClinicVisits.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargas las visitas externas",
          });
        })
        .finally(() => {
          this.externalClinicVisits.isLoading = false;
        });
    },
    getStudentsClinicVisits() {
      this.studentsClinicVisits.data = [];
      const { page, itemsPerPage } = this.options;
      this.studentsClinicVisits.isLoading = true;
      if (!!page && !!itemsPerPage) {
        nursingRepository
          .getStudentClinicVisitsPagination(page, itemsPerPage)
          .then(({ data }) => {
            this.studentsClinicVisits.data = data.data;
            this.totalItems = data.count;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No ha sido posible cargas las visitas de los estudiantes",
            });
          })
          .finally(() => {
            this.studentsClinicVisits.isLoading = false;
          });
      }
    },
    findStudentVisit() {
      if (
        this.studentsClinicVisits.search.trim().length === 0 ||
        this.studentsClinicVisits.search === null
      ) {
        this.fireToast({
          icon: "error",
          title: "Tiene que ingresar el código de estudiante",
        });
        return;
      }
      this.studentsClinicVisits.data = [];
      this.studentsClinicVisits.isLoading = true;
      nursingRepository
        .getStudentClinicVisit(this.studentsClinicVisits.search)
        .then(({ data }) => {
          this.studentsClinicVisits.data = data.data;
          this.totalItems = this.studentsClinicVisits.data.length;
        })
        .catch((err) => {
          console.log(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargas las visitas de los estudiantes",
          });
        })
        .finally(() => {
          this.studentsClinicVisits.isLoading = false;
        });
    },
    cleanSearchFilter() {
      this.studentsClinicVisits.search = "";
      this.getStudentsClinicVisits();
    },
    getStudentClinicVisitsToday() {
      this.studentClinicVisitsToday.isLoading = true;
      nursingRepository
        .getStudentsClinicVisitsAmountByRange(this.currentDay, this.currentDay)
        .then(({ data }) => {
          this.studentClinicVisitsToday.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar las visitas de hoy",
          });
        })
        .finally(() => {
          this.studentClinicVisitsToday.isLoading = false;
        });
    },
    getClinicVisitsByMonth() {
      this.clinicVisitsByMonth.isLoading = true;
      nursingRepository
        .getAllClinicVisitsByDate(this.currentDay)
        .then(({ data }) => {
          this.clinicVisitsByMonth.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar las visitas del mes",
          });
        })
        .finally(() => {
          this.clinicVisitsByMonth.isLoading = false;
        });
    },
    getVitalSigns() {
      this.vitalSigns.isLoading = true;
      nursingRepository
        .getAllVitalSigns()
        .then(({ data }) => {
          this.vitalSigns.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los signos vitales",
          });
        })
        .finally(() => {
          this.vitalSigns.isLoading = false;
        });
    },
    async getMedicines() {
      try {
        this.medicines.isLoading = true;
        const res = await axios.get(`${this.getFormApiURL}/medicines`);
        this.medicines.data = res.data;
        this.medicines.isLoading = false;
      } catch (error) {
        this.medicines.isLoading = false;
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los medicamentos",
        });
      }
    },
    async getDiseases() {
      try {
        this.diseases.isLoading = true;
        const res = await axios.get(`${this.getFormApiURL}/diseases`);
        this.diseases.data = res.data;
        this.diseases.isLoading = false;
      } catch (error) {
        this.diseases.isLoading = false;
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los medicamentos",
        });
      }
    },
    showMdlMedicines() {
      this.$refs.mdlMedicine.toggleModal();
    },
    showMdlDiseases() {
      this.$refs.mdlDisease.toggleModal();
    },
    showMdlVitalSigns() {
      this.$refs.mdlVistalSigns.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    selectItem(obj) {
      this.selectedVisit = { ...obj };
    },
    deleteVisit() {
      nursingRepository
        .deleteClinicVisit(this.selectedVisit.visit_id)
        .then(({ data }) => {
          this.fireToast({
            title: data.message,
            icon: "success",
          });
          this.getStudentsClinicVisits();
        })
        .catch((error) => {
          this.fireToast({
            title: error.message,
            icon: "error",
          });
        });
    },
    getStudentVisitDetailedReport(visitID, date, studentCode) {
      // Find the corresponding item in the formattedStudentsClinicVisits array
      const item = this.formattedStudentsClinicVisits.find(
        (item) => item.visit_id === visitID
      );

      if (item) {
        // Set loading state to true for the corresponding row
        item.loading = true;
        axios({
          url: `${this.DP_URL}/clinic-visit/report/id/${visitID}`,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `reporte visita enfermería ${studentCode} - ${this.fullFormattedDate(
                date
              )}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch((e) => {
            this.fireToast({
              title: "Ocurrió un error al descargar el reporte",
            });
          })
          .finally(() => {
            item.loading = false;
          });
      }
    },
    goToStudentDisciplineDashboard() {
      this.$router.push({
        path: "/discipline_dashboard",
      });
    },
  },

  computed: {
    DP_URL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },
    formattedStudentsClinicVisits() {
      return this.studentsClinicVisits.data.map((item) => ({
        photo: item.patient.photo,
        visit_id: item.id,
        visit_date: item.visit_date_time,
        full_name: `${item.patient.first_name} ${item.patient.last_name}`,
        code: item.patient.code,
        grade: `${item.patient.grade} ${item.patient.technical_group}`,
        medication: item.medication,
        illness: item.illness,
        vital_signs: item.vital_signs,
        diagnostic: item.diagnostic,
        loading: false,
      }));
    },
    formattedExternalPeopleClinicVisits() {
      return this.externalClinicVisits.data.map((item) => ({
        full_name: `${item.patient.first_name} ${item.patient.last_name}`,
        patient_clasification: item.patient.ExternalPeopleClassification.name,
        medication: item.medication,
        illness: item.illness,
        vital_signs: item.vital_signs,
        diagnostic: item.diagnostic,
        visit_id: item.id,
        visit_date: item.visit_date_time,
      }));
    },
    getFormApiURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
    countMedicines() {
      return this.medicines.data.length;
    },
    countDiseases() {
      return this.diseases.data.length;
    },
    countVitalSigns() {
      return this.vitalSigns.data.length;
    },
    countStudentVisistToday() {
      return this.studentClinicVisitsToday.data.length;
    },
    countClinicVisitByMonth() {
      return this.clinicVisitsByMonth.data.length;
    },
  },
};
</script>
